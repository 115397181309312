<template>
  <div class="emails-list" v-if="isPageReady">
    <Action-panel :isReady="isPageReady">
    </Action-panel>
    <v-card class="ma-6">
      <v-tabs v-model="selectedTab" flat color="primary">
        <v-tab
          v-for="tab in tabs"
          :key="tab.id"
          :href="`#tab-${tab.id}`"
          centered
          icons-and-text
        >
          {{ tab.description }}
        </v-tab>
        <v-tabs-items v-model="selectedTab">
          <!-- templates -->
          <v-tab-item
            key="tab-templates"
            value="tab-templates"
          >
            <v-card :isReady="isPageReady">
              <div class="emails-list__actionbar">
                <Button-base
                  :type="2"
                  :text="translations.labels.emailLayout_table_addTemplate"
                  :hoverInfo="translations.labels.emailLayout_table_addTemplate_hover"
                  :onClick="newTemplate"
                />
              </div>
              <TableList
                :is-ready="isPageReady"
                :headers="templateHeaders"
                :items="templates"
                :items-per-page="-1"
                sortable
                item-key="id"
                :search-visible="false"
              />
            </v-card>
          </v-tab-item>
          <!-- layouts -->
          <v-tab-item
            key="tab-layouts"
            value="tab-layouts"
          >
            <div :isReady="isPageReady">
              <div class="emails-list__actionbar">
                <Button-base
                  :type="2"
                  :text="translations.labels.emailLayout_table_addLayout"
                  :hoverInfo="translations.labels.emailLayout_table_addLayout_hover"
                  :onClick="newLayout"
                />
              </div>
            </div>
            <v-card :isReady="isPageReady">
              <TableList
                :is-ready="isPageReady"
                :headers="layoutHeaders"
                :items="layouts"
                :items-per-page="-1"
                sortable
                item-key="id"
                :search-visible="false"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/dom/ConfirmDialog";
import Vue from "vue";
import Component from "vue-class-component";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";

@Component({
  inject: {
    toastService: "toastService",
    emailService: "emailService",
  },
  components: {
    ActionPanel,
    InnerPanel,
    TablePanel,
    ConfirmDialog,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    TableList
  }
})
export default class EmailsList extends Vue {
  selectedTab = "tab-layouts";
  isPageReady = false;
  templates = [];
  layouts = [];

  get tabs() {
    return [
      {
        id: "templates",
        description: "Templates"
      },
      {
        id: "layouts",
        description: "Layouts"
      }
    ];
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get templateHeaders() {
    return [
      {
        text: this.translations.labels.emailTemplate_table_code,
        value: "code",
        sortable: true,
      },
      {
        text: this.translations.labels.emailTemplate_table_languageCode,
        value: "languageCode",
        sortable: true,
      },
      {
        text: this.translations.labels.emailTemplate_table_emailSubject,
        value: "emailSubject",
        sortable: false,
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        sortable: false,
        type: "link",
        formatValue: (item) => {
          return `/configurations/emails/templates/edit/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
    ];
  }

  get layoutHeaders() {
    return [
      {
        text: this.translations.labels.emailLayout_table_code,
        value: "code",
        sortable: true,
      },
      {
        text: this.translations.labels.emailLayout_table_languageCode,
        value: "languageCode",
        sortable: true,
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        sortable: false,
        type: "link",
        formatValue: (item) => {
          return `/configurations/emails/layouts/edit/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
    ];
  }

  async init() {
    this.selectedTab = this.$route.params.tab ?? "templates";
    this.selectedTab = "tab-" + this.selectedTab;
    try {
      this.layouts = await this.emailService.listLayouts();
      this.templates = await this.emailService.listTemplates();
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }

    this.isPageReady = true;
  }

  newLayout() {
    this.$router.push("/configurations/emails/layouts/new");
  }

  newTemplate() {
    this.$router.push("/configurations/emails/templates/new");
  }

  mounted() {
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.emails-list {
  &__actionbar {
    padding: 25px;
    text-align: right;
  }
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 0 30px;
    margin: 15px 30px 0 30px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
